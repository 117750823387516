var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selected_workspace)?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.headers,"loading":_vm.loading,"disable-sort":"","disable-pagination":"","fill-height":"","items":_vm.secrets,"item-key":"_id","dense":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('label.no_data_available'))+" ")]},proxy:true},{key:"header.actions",fn:function(ref){return [(_vm.showTrash && (_vm.selected_workspace.is_owner || _vm.selected_workspace.can_write))?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('help.empty_trash')))])])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"16px"}},[_c('small',[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$t("warning.confirm_empty_trash"))+" ")],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v(_vm._s(_vm.$t('button.cancel')))]),_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":_vm.emptyTrash}},[_vm._v(_vm._s(_vm.$t('button.confirm')))])],1)],1)],1):_vm._e(),(_vm.showTrash && (_vm.selected_workspace.is_owner || _vm.selected_workspace.can_write))?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-delete-empty ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('help.restore_trash')))])])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"16px"}},[_c('small',[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$t("warning.confirm_restore_trash"))+" ")],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v(_vm._s(_vm.$t('button.cancel')))]),_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":_vm.restoreTrash}},[_vm._v(_vm._s(_vm.$t('button.confirm')))])],1)],1)],1):_vm._e(),(!_vm.showTrash && (_vm.selected_workspace.is_owner || _vm.selected_workspace.can_write))?_c('v-btn',{staticStyle:{"float":"right"},attrs:{"tile":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addSecret(_vm.category, _vm.current_folder)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index == 0 && _vm.showTrash)?_c('td',{staticClass:"restore",attrs:{"colspan":"12"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$t("warning.restore"))+" ")],1):_vm._e(),(_vm.headers)?_c('draggable',{key:item._id,staticClass:"text-left",attrs:{"list":_vm.secrets,"tag":"tr","id":item._id},on:{"start":_vm.startDrag,"end":_vm.endDrag}},_vm._l((_vm.headers),function(header,i){return _c('td',{key:i},[(header.component === 'SecretCell')?_c('span',[_c('secret-cell',{attrs:{"category":_vm.category,"field":header.value,"item":item}})],1):(header.component === 'UrlCell')?_c('span',[_c('url-cell',{attrs:{"category":_vm.category,"field":header.value,"item":item}})],1):(header.component === 'ActionCell')?_c('span',[_c('action-cell',{attrs:{"can_share_external":_vm.can_share_external,"category":_vm.category,"notif":_vm.configuredNotifs.includes(item._id),"item":item},on:{"delete":_vm.deleteItem,"restore":_vm.restoreItem}})],1):_c('span',[_c('string-cell',{attrs:{"category":_vm.category,"field":header.value,"item":item}})],1)])}),0):_vm._e()]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }