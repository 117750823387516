var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticStyle:{"float":"right"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('v-card',{staticClass:"text-left scroll",attrs:{"width":"500","max-height":"500","flat":""}},[_c('v-app-bar',{staticClass:"edit_workspace_bar",attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('label.last_update'))+": "),_c('strong',[_vm._v(_vm._s(_vm.renderDate(_vm.item.updated_at)))])])],1),_c('v-card-text',[(_vm.item.created_by)?_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v(_vm._s(_vm.$t('label.created_by'))+":")]),_vm._v(" "+_vm._s(_vm.item.created_by)+" ")]):_vm._e(),(_vm.item.updated_by)?_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v(_vm._s(_vm.$t('label.updated_by'))+":")]),_vm._v(" "+_vm._s(_vm.item.updated_by)+" ")]):_vm._e(),(_vm.item.password_last_change)?_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v(_vm._s(_vm.$t('label.last_change_password'))+":")]),_vm._v(" "+_vm._s(_vm.renderDate(_vm.item.password_last_change))+" ")]):_vm._e(),_c('pre',[_vm._v(_vm._s(_vm.item.informations.value))])])],1)],1),(_vm.is_pro)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user.email === _vm.item.created_by)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":_vm.notif ? 'primary' : 'white',"loading":_vm.loadingNotif,"small":""},domProps:{"innerHTML":_vm._s(_vm.notif ? 'mdi-bell-ring' : 'mdi-bell')},on:{"click":function($event){return _vm.createNotif(_vm.notif)}}},'v-icon',attrs,false),on)):_vm._e()]}}],null,false,2370100146)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.notif ? _vm.$t('help.warning_secret_use_disable') : _vm.$t('help.warning_secret_use'))}})]):_vm._e(),(!_vm.showTrash && _vm.can_share_external && _vm.is_pro)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-share-all ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('help.external_share')))])])]}}],null,false,3884094651)},[_c('v-card',[_c('context-key',{attrs:{"data":_vm.item,"sms":_vm.twilioEnabled}})],1)],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyData(_vm.item._id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}]),model:{value:(_vm.tooltip_copy),callback:function ($$v) {_vm.tooltip_copy=$$v},expression:"tooltip_copy"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tooltipHTML)}})]),((_vm.selected_workspace.is_owner || _vm.selected_workspace.can_write))?_c('span',[(!_vm.showTrash)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editSecret(_vm.category, _vm.item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,false,1692467924)},[_c('span',[_vm._v(_vm._s(_vm.$t('help.edit_key')))])]):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('help.delete_key')))])])]}}],null,false,2260399453)},[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"16px"}},[_c('small',[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$t('warning.confirm_delete'))+" ")],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v(_vm._s(_vm.$t('button.cancel')))]),_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){return _vm.$emit('delete', _vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)],1)],1),(_vm.showTrash)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-delete-empty ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('help.restore_key')))])])]}}],null,false,2456704820)},[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"16px"}},[_c('small',[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$t('warning.confirm_restore_key'))+" ")],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v(_vm._s(_vm.$t('button.cancel')))]),_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){return _vm.$emit('restore', _vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)],1)],1):_vm._e()],1):_vm._e(),_c('portal',{attrs:{"to":"contextMenuKey"}},[_c('vue-context',{ref:"menuKey",staticClass:"p-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('context-key',{attrs:{"data":data,"sms":_vm.twilioEnabled}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }